<template>
  <div class="pt-2">
    <v-text-field
      v-if="dt.is_text_field"
      :label=dt.label
      color="black"
      :type=dt.type
      :disabled="data_id == `create` ? dt.disabled : disabled_content"
      v-model="dt.value"
    ></v-text-field>

    <div class="mb-3 mt-3" v-if="dt.is_editor">
      <p>{{dt.label}}</p>
      <ckeditor 
        :read-only="data_id == `create` ? dt.disabled : disabled_content"
        v-model="dt.value" 
        :config="editorConfig"
      >
      </ckeditor>
    </div>
    
    <v-textarea
      v-if="dt.is_textarea"
      :label=dt.label
      color="black"
      :disabled="data_id == `create` ? dt.disabled : disabled_content"
      v-model="dt.value"
    ></v-textarea>

    <v-switch
      v-if="dt.is_switch"
      v-model="dt.value"
      :disabled="data_id == `create` ? dt.disabled : disabled_content"
      :label=dt.label
      color="black"
    ></v-switch>

    <!-- BUAT CATEGORY PAS DI EDIT -->
    <!-- <CustomSwitch
      v-if="custom_component && dt.component_custom_switch"
      :dt=dt
      :data=data
      :disabled_content=disabled_content
    /> -->
    
    <div v-if="dt.is_custom && dt.custom_component.length > 0">
      <p>{{dt.label}}</p>
      <CustomComponent
        :dt=dt.custom_component
        :val=dt
        :disabled_content=disabled_content
        :data_id=data_id
      />
    </div>

    <v-autocomplete
      v-if="dt.is_select"
      :items="(dt.options) ? dt.options : null"
      :disabled="data_id == `create` ? dt.disabled : disabled_content"
      v-model=dt.value
      :item-text="(dt.options) ? dt.options.text : null"
      :item-value="(dt.options) ? dt.options.value : null"
      :multiple=dt.is_multiple
      :chips=dt.is_multiple
      :label=dt.label
      color="black"
      item-color="black"
    ></v-autocomplete>

    <span v-if="dt.is_tree_view" style="opacity: 0.6; font-size: 13px;">{{ dt.label }}</span>
    <v-treeview
      v-if="dt.is_tree_view"
      item-disabled="locked"
      selected-color="black" rounded hoverable selectable
      v-model="dt.value"
      :items="getOpt(dt.options)"
    ></v-treeview>

    <v-menu
      v-if="dt.is_date_picker"
      v-model="date_picker_menu"
      :close-on-content-click="false"
      :nudge-right="40"
      offset-y
      transition="scale-transition"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model=dt.value
          :disabled="data_id == `create` ? dt.disabled : disabled_content"
          :label=dt.label
          color="black"
          clearable
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model=dt.value
        @input="date_picker_menu = false"
      ></v-date-picker>
    </v-menu>

    <v-file-input
      v-if="dt.is_file && btn_file_edit"
      :disabled="data_id == `create` ? dt.disabled : disabled_content"
      :v-model=dt.value
      :value=dt.value
      multiple
      :suffix="dt.value ? null : dt.files.map(dtf => dtf.file_name).join(`, `)"
      accept="image/*,application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
      small-chips color="black"
      @change="(e) => getFile(dt.attribute, e)"
      :label=dt.label
    >
    </v-file-input>
    <v-text-field
      v-if="!btn_file_edit && dt.is_file"
      :label=dt.label
      color="black"
      disabled
      :value="dt.files.map(dfm => dfm.file_name).join(', ')"
    ></v-text-field>

    <v-row class="px-5">
      <div v-for="img in dt.files" :key="img.value" class="mr-2 align-self-center">
        <a v-if="!img.file_name.includes(`_file`)" :href="`${img.full_path}`" target="_blank" rel="noopener noreferrer">
          <v-img
            v-if="disabled_content"
            width="200"
            :src="`${img.full_path}`"
          ></v-img>
        </a>

        <v-btn
          v-else-if="img.file_name.includes(`_file`)"
          depressed
          class="mb-2 mr-2 btn-border"
          :href="img.full_path"
          target="_blank"
        >
          View file
        </v-btn>
      </div>
    </v-row>

    <!-- for using array buffer -->
    <!-- <v-row class="px-5">
      <div v-for="img in dt.files" :key="img.value" class="mr-2 align-self-center">
        <a :href="`${url_server}${img.file_path}`" target="_blank" rel="noopener noreferrer">
          <v-img
            v-if="disabled_content"
            width="200"
            :src="`${url_server}${img.file_path}`"
          ></v-img>
        </a>
      </div>
    </v-row> -->
  </div>
</template>

<script>
  import CustomComponent from '@/components/Form/CustomComponent.vue'

  export default {
    props: [`dt`, `disabled_content`, `btn_file_edit`, `data_id`, `editorConfig`, `date_picker_menu`, `url_server`],
    components: {
      CustomComponent
    },
    data () {
      return {
        form: {},
      }
    },
    methods: {
      getFile(attr, e) {
        this.$emit('getFile', attr, e)
      },
      getOpt(data) {
        data.map(dm => {
          dm.locked = this.data_id == `create` ? false : this.disabled_content;
          if (dm.children.length > 0) dm.children.map(cdm => {cdm.locked = this.data_id == `create` ? false : this.disabled_content;})
        })
        return data
      }
    },
  }
</script>

<style scoped>
  .tableview {
    padding: 20px 30px;
  }
</style>
